import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";

const Content = () => {
  return (
    <Layout>
      <Seo title="Signs you addicted to Dr Pepper" description="Signs to tell if you are addicted to Dr Pepper" />
      <Container>
        <Row>
          <Col>
            <h1>What Are the Signs?</h1>
            <h3 className="mb-3">You are a Dr Pepper Addict if you,</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <ul className="mb-5">
              <li>Say you have a problem or joke about drinking Dr Pepper</li>
              <li>Lose friendships or have relationship problems due to drinking, but you don’t quit Dr Pepper</li>
              <li>Need Dr Pepper to relax or feel confident</li>
              <li>Drink Dr Pepper in the morning or when you’re alone</li>
              <li>Get high on Dr Pepper when you don’t intend to</li>
              <li>Forget what you did while drinking Dr Pepper</li>
              <li>Deny drinking, hide Dr Pepper's, or get angry when confronted about drinking Dr Pepper</li>
              <li>Cause loved ones to worry about or make excuses when drinking Dr Pepper</li>
            </ul>
          </Col>
        </Row>
        <ProductHighlight />
      </Container>
    </Layout>
  );
};

export default Content;
